<template>
  <v-container fluid>
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-container
        id="regular-tables"
        fluid
        tag="section"
        class="pa-3"
      >
        <v-data-table
          :headers="headers"
          :items="list"
          :loading="isLoading"
          :server-items-length="meta.totalItems"
          :options.sync="options"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
        >
          <template v-slot:item.qte_outbound="{ item }">
            <v-btn
              text
              plain
              large
              @click="showOutboundModal(item)"
            >
              {{ item.qte_outbound }}
            </v-btn>
          </template>
          <template v-slot:item.qte_inbound="{ item }">
            <v-btn
              text
              plain
              large
              @click="showInboundModal(item)"
            >
              {{ item.qte_inbound }}
            </v-btn>
          </template>
          <template v-slot:item.qte_adjustment="{ item }">
            <v-btn
              text
              plain
              large
              @click="showAdjustmentModal(item)"
            >
              {{ item.qte_adjustment }}
            </v-btn>
          </template>
          <template v-slot:item.qte_in_hubs="{ item }">
            <v-btn
              text
              plain
              large
              @click="showDetailsModal(item)"
            >
              {{ item.qte_in_hubs }}
            </v-btn>
          </template>
          <template v-slot:item.qte_transfer="{ item }">
            <v-btn
              text
              plain
              large
              @click="showTransfersModal(item)"
            >
              {{ item.qte_transfer }}
            </v-btn>
          </template>

          <template v-slot:no-data>
            {{ $t("no_data_available") }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("filter_options") }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                v-if="$admin.can('stockManagement-export')"
                class="mr-4 v-btn v-btn--outlined theme--light v-size--default primary--text"
                @click="getCSVResult(options)"
                :loading="is_loading_csv"
                :disabled="is_loading_csv"
              >
                {{ $t("stockManagements.export_csv") }}
              </v-btn>
            </v-toolbar>
            <v-row>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="options.search"
                  :label="$t('search')"
                  class=""
                ></v-text-field>
              </v-col>
              <v-col md="3">
                <v-autocomplete
                  outlined
                  clearable
                  dense
                  v-model="options.hub_ids"
                  :items="hubs"
                  item-text="name"
                  item-value="id"
                  :label="$t('hubs')"
                  :search-input.sync="search_hub"
                  :loading="isLoadingHubs"
                  no-filter
                  multiple
                  chips
                ></v-autocomplete>
              </v-col>
              <v-col md="3">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      clearable
                      outlined
                      v-model="options.date_range"
                      :label="$t('date_range')"
                      append-icon="mdi-calendar"
                      readonly
                      chips
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="options.date_range"
                    range
                    no-title
                    color="primary"
                    class="my-0 py-4"
                    header-color="primary"
                  ></v-date-picker> </v-menu
              ></v-col>
            </v-row>
            <div>
              <v-card
                flat
                elevation="0"
                class="ma-0"
                width="50%"
              >
                <v-toolbar
                  flat
                  dense
                >
                  <v-toolbar-title class="text-h4">
                    {{ $t("stockManagements.item_batch_details") }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pl-0">
                  <div class="d-flex flex-row">
                    <v-img
                      class="me-4"
                      max-height="200"
                      max-width="200"
                      v-if="productEntity?.image"
                      :src="productEntity?.image?.url"
                    />
                    <v-img
                      v-else
                      class="me-4"
                      src="@/assets/product.png"
                      max-height="100"
                      max-width="100"
                    />
                    <div>
                      <label class="ma-1 font-weight-regular">
                        {{ productEntity?.name }}
                      </label>
                      <br />
                      <label class="text--disabled ma-1 body-1">
                        ID: {{ productEntity?.generated_code }} - Barcode :
                        {{ productEntity?.barcode }}
                      </label>
                      <br />
                      <label class="text--disabled ma-1 body-1">
                        Supplier: {{ productEntity?.provider.name }} - Category
                        : {{ productEntity?.category.name }} - Brand :
                        {{ productEntity?.brand?.name }}
                      </label>
                      <br />
                      <label class="text--disabled ma-1 body-1">
                        purchase price: {{ productEntity?.purchase_price }} -
                        Price :
                        {{ productEntity?.price }}
                      </label>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
    <div class="mt-8 d-flex flex-row-reverse">
      <v-btn
        class="ml-4 custom_info_btn"
        text
        @click="returnToList()"
      >
        {{ $t("stockAdjustments.back") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import productService from "@/store/services/product-service";

export default {
  components: {},

  computed: {
    ...mapGetters({
      isLoadingHubs: "hubs/isLoadingHubs",

      hubs: "hubs/listHubs",

      isLoading: "stockManagementsBatch/isLoading",
      list: "stockManagementsBatch/list",
      meta: "stockManagementsBatch/meta",
    }),

    options: {
      get() {
        return this.$store.state.stockManagementsBatch.filters;
      },
      set(value) {
        this.$store.commit("stockManagementsBatch/SET_FILTERS", value);
      },
    },
  },

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading = true;
        this.options.product_id = this.$route.params.id;
        await this.$store.dispatch("stockManagementsBatch/list", this.options);
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  async mounted() {
    try {
      this.productEntity = await productService.show({
        id: this.$route.params.id,
      });
      await this.$store.dispatch("hubs/list");
      await this.$store.dispatch("stockManagementsBatch/list", {
        itemsPerPage: 30,
        product_id: this.$route.params.id,
      });
    } catch (error) {
      if (error.response?.status == "401") {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  data() {
    return {
      currentItem: null,

      search_hub: "",
      is_loading: false,
      is_loading_csv: false,
      productEntity: null,

      headers: [
        {
          text: this.$t("date"),
          align: "start",
          sortable: true,
          value: "date",
        },
        {
          text: this.$t("hub"),
          align: "start",
          sortable: true,
          value: "hub_name",
        },
        {
          text: this.$t("batch"),
          align: "start",
          sortable: true,
          value: "lot_number",
        },
        {
          text: this.$t("expiration_date"),
          align: "center",
          sortable: true,
          value: "expiration_date",
        },
        {
          text: this.$t("stockManagements.qte_inbound"),
          align: "center",
          sortable: true,
          value: "qte_inbound",
        },
        {
          text: this.$t("stockManagements.qte_outbound"),
          align: "center",
          sortable: true,
          value: "qte_outbound",
        },
        {
          text: this.$t("stockManagements.qte_adjustment"),
          align: "center",
          sortable: true,
          value: "qte_adjustment",
        },

        {
          text: this.$t("stockManagements.qte_in_hubs"),
          align: "center",
          sortable: true,
          value: "qte_in_stock",
        },

        {
          text: this.$t("stockManagements.qte_transfer"),
          value: "qte_transfer",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("stockManagements.purchase_price_ht"),
          value: "purchase_price",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    getQteColor(value) {
      if (value > 0) return "green--text text--darken-3";
      else if (value < 0) return "red--text text--darken-3";
      else return "";
    },
    returnToList() {
      this.$router.push({ name: "stock.managements" });
    },
    async getCSVResult(filters) {
      filters.all = true;
      this.is_loading_csv = true;
      await this.$store
        .dispatch("stockManagementsBatch/exportCSVResult", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "batches.xlsx");
          document.body.appendChild(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
  },
};
</script>
<style>
.v-toolbar__content {
  padding-left: 0;
}
</style>